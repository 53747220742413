<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p> Shout out to former members : </p>
    <ul>
	<li>IronMug</li>
	<li>Gemini</li>
	<li>Stoic</li>
	<li>Sigma</li>
	<li>Orbit</li>
	<li>TelMarien</li>
	<li>DarkSoul</li>
	<li>Skewed</li>
	<li>Doomsday</li>
	<li>Xanatos</li>
    </ul>
    <p>Site currently owned and operated by <a href="mailto:xanatos@t-l-a.org">Xanatos.</a></p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
